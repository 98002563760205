import './App.css';
import React, { useState, useEffect } from 'react';
import { GithubPicker } from 'react-color';
import {useInterval, getNoteName} from './utils'

function App() {

  const [count, setCount] = useState(0);
  const [color, setColor] = useState("#fff");
  const [picker, setPicker] = useState(false);
  const [outline, setOutline] = useState(1);
  const [modulo, setModulo] = useState(17);
  const [multiple, setMultiple] = useState(3);
  const [add, setAdd] = useState(7);
  const [types, setTypes] = useState(initTypes(128));
  const [primary, setPrimary] = useState("#fff");
  const [secondary, setSecondary] = useState("#ffd300");
  const [background, setBackground] = useState("#41256C");
  const [toggled, setToggled] = useState(Array(128).fill(background));  

  // const [tick, setTick] = useState(0);
  // useInterval(() => { setTick(tick+1); updateCanvas() },200)

  function initTypes(len) {
    let result = []
    for (let i=0; i<len; i++) {
      result.push((i%modulo*multiple+add)%4)
    }
    return result
  }

  function getColor(str) {

    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color
  }

  function join(list) {
    return list.map(e => e.join(",")).join(" ")
  }

  function getOffset(conf) {
    return 2*(conf.i*8+conf.j)+conf.z
  }

  function toggleVal(data,idx) {
    // data[idx] = !data[idx]
    data[idx] = color
    return data
  }

  function getStroke(conf) {
      return "rgba(255,255,255,0.2)"
  }

  function polygon(conf, coords) {
    return <polygon points={join(coords)} fill={toggled[getOffset(conf)]} strokeWidth={outline} stroke={getStroke(conf)} className="triangle" 
                    onClick={() => {
                      setCount(count+1)
                      setToggled(toggleVal(toggled, getOffset(conf)))
                    }}/>
  }

  // generate a given triangle rotation

  function getTriangle(conf, filled) {
    let dim = conf.dim
    switch (conf.type) {
      case 0:
        return polygon(conf, [[conf.x,conf.y],[conf.x,conf.y+dim],[conf.x+dim,conf.y]])
      case 1:
        return polygon(conf, [[conf.x+dim,conf.y],[conf.x+dim,conf.y+dim],[conf.x,conf.y+dim]])
      case 2:
        return polygon(conf, [[conf.x,conf.y],[conf.x+dim,conf.y+dim],[conf.x,conf.y+dim]])
      case 3:
        return polygon(conf, [[conf.x,conf.y],[conf.x+dim,conf.y],[conf.x+dim,conf.y+dim]])
    }
  }

  function getVisualHash(word) {
    let nodes = []
    let dim = 60
    let count = 8
    // let color = getColor(word)
    // console.log(color)

    let complementary = [1,0,3,2]
    // let compColor = ["#41256C", "#41256C", "#41256C", "#291745"]  
    let compColor = [background, background, background, "#291745"]

    for (let i=0; i<count;i++) {
      for (let j=0; j<count; j++) {
        // let type = Math.floor(Math.random()*4)
        let type = types[i*count+j]
        nodes.push(getTriangle({x:i*dim,y:j*dim,dim:dim,i:i, j:j, z:0, count: count, color:color, type:type}))
        nodes.push(getTriangle({x:i*dim,y:j*dim,dim:dim,i:i, j:j, z:1, count: count, color:compColor[type], type:complementary[type]}))
      }
    }
    return <svg xmlns="http://www.w3.org/2000/svg" height={dim*count} width={dim*count} id="logo">
          {nodes}
          </svg>
  }

  function swapColor() {
    if (color == "#fff") {
      setColor("#2A1745")
    } else {
      setColor("#fff")
    }
  }

  function toggleOutline() {
    if (outline == 1) {
      setOutline(0)
    } else {
      setOutline(1)
    }
  }

  function randomizeCanvas() {
    console.log("randomize")
    let data = []
    // let colors=["#b500c6", "#e50073", "#96a500", "#281746", "#ffffff", "#ebc1ff", "#00bbec", "#000000", "#b09eff", "#41256c"]
    let colors = ["#41256C", "#281746", "#FFFFFF", "#000000"]
    let seed = Math.floor(Math.random()*128)
    let mod = 5

    for (let i=0; i<128; i++) {
      // data.push("rgba(255,255,255," + Math.random() + ")")
      if (Math.random() < 0.8) {
        // data.push(colors[Math.floor(Math.random()*colors.length)])
        // data.push(colors[((seed + i) % mod)%colors.length])
        data.push(colors[Math.floor((i+1)/mod)%colors.length])
      } else {
        data.push("#41256C")
      }
    }
    setToggled(data)
  }


  // function updateCanvas() {
  //   let data = []
  //   let colors = ["#41256C", "#281746", "#FFFFFF", "#000000"]
  //   let mod = tick

  //   for (let i=0; i<128; i++) {
  //     data.push(colors[(Math.floor(i/(tick%32)) + tick%11)%colors.length])
  //   }
  //   setToggled(data)
  // }


   function TextFile() {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById('logo').outerHTML], {type: 'image/svg+xml'});
    element.href = URL.createObjectURL(file);
    element.download = "logo.svg";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  return (    
    <div className="App" style={{backgroundColor: background}}>
      <div className="App-container" style={{backgroundColor: background}}>

              {/*Title*/}
              
              <div className="Title">
                <h1><div className="strong-serif">Remoticon.</div><div className="light-mono">sketchpad</div></h1>
                <h2>Grid Design Playground</h2>
              </div>
            

              {/*Top Menu*/}
              
{/*              <div className="ControlSection TopMenu">
                <h3>Grid Configuration</h3>
                <div className="OptionsRow">
                  <div className="ConfigOption">
                    <h4>Modulo</h4>
                    <textarea value={modulo} onChange={(event) => { setModulo(event.target.value); setTypes(initTypes(128));}}></textarea>
                  </div>
                  <div className="ConfigOption">
                    <h4>Add</h4>
                    <textarea value={add} onChange={(event) => { setAdd(event.target.value); setTypes(initTypes(128));}}></textarea>
                  </div>
                  <div className="ConfigOption">
                    <h4>Multiple</h4>
                    <textarea value={multiple} onChange={(event) => { setMultiple(event.target.value); setTypes(initTypes(128));}}></textarea>
                  </div>
                </div>
              </div>*/}

              {/*Grid*/}
              {getVisualHash("test")}


              {/*Bottom Menu 1*/}
              
              <div className="ControlSection BottomMenu">

                <div className="CompositeMenu">
                  <h3>Drawing Tools</h3>
                  <div className="OptionsRow">
                    
                    <div className="ConfigOption">
                      <h4>Color</h4>
                      <div className="setColorToggle"  onClick={() => {setPicker(!picker)}}>
                          <div className="active-color" style={{backgroundColor :color}}></div>
                          <div className="text-button">{color}</div>                  
                      </div>
                        {(picker == true) && <GithubPicker 
                        color={ color }
                        // width="210%"
                        width="90%"
                        // colors={["#000", "#41256C", "#281746", "#00bbec", "#fff", "#b500c6", "#140c23", "#53456b" ]}
                        // colors={["#000", "#41256C", "#281746", "#00bbec", "#fff" ]}
                        // colors={["#b500c6", "#e50073", "#96a500", "#281746", "#ffffff", "#ebc1ff", "#00bbec", "#000000", "#b09eff", "#41256c"]}
                        colors={["#41256C", "#281746", "#FFFFFF", "#000000"]}
                        onChangeComplete={ (color) => { setColor(color.hex) } }
                          />}

                    </div>

                    <div className="ConfigOption">
                      <h4>Grid</h4>
                      <div className="setColorToggle" onClick={() => {toggleOutline()}}>
                          <div className="text-button">{outline == 1 ? "ON" : "OFF"}</div>                  
                      </div>
                    </div>

                  </div>

                </div>


                <div className="CompositeMenu">
                  <h3>Commands</h3>
                  <div className="OptionsRow">                    
                    <div className="ConfigOption">
                        <div className="text-action" onClick={() => {setToggled(Array(128).fill(background))}}>[ ✗ &nbsp;Clear ]</div>                  
                        <div className="text-action" onClick={() => {randomizeCanvas()}}>[ ® &nbsp;Randomize ]</div>                  
                        <div className="text-action" onClick={() => {TextFile()}}>[ ⬇ Export ]</div>                  
                    </div>
                  </div>
                
                </div>

              </div>

              {/*Bottom Menu 2 */}
{/*              <div className="ControlSection BottomMenu">
                <div className="CompositeMenu">
                <h3>Color Palette</h3>
                  <div className="OptionsRow">
                  <div className="ConfigOption">
                      <h4>Primary</h4>
                      <div className="setColorToggle"  onClick={() => {swapColor()}}>
                          <div className="active-color" style={{backgroundColor :color}}></div>
                          <div className="text-button">{primary}</div>                  
                      </div>
                    </div>
                    <div className="ConfigOption">
                      <h4>Secondary</h4>
                      <div className="setColorToggle"  onClick={() => {swapColor()}}>
                          <div className="active-color" style={{backgroundColor :secondary}}></div>
                          <div className="text-button">{secondary}</div>                  
                      </div>
                    </div>
                    <div className="ConfigOption">
                      <h4>Background</h4>
                      <div className="setColorToggle"  onClick={() => {setBackground("#000")}}>
                          <div className="active-color" style={{backgroundColor :background}}></div>
                          <div className="text-button">{background}</div>                  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
*/}
  
            </div>
  </div>
  );
}

export default App;
